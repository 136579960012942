exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-books-four-liars-in-space-js": () => import("./../../../src/pages/en/books/four-liars-in-space.js" /* webpackChunkName: "component---src-pages-en-books-four-liars-in-space-js" */),
  "component---src-pages-en-books-kalipso-js": () => import("./../../../src/pages/en/books/kalipso.js" /* webpackChunkName: "component---src-pages-en-books-kalipso-js" */),
  "component---src-pages-en-books-married-to-the-evil-wizard-king-js": () => import("./../../../src/pages/en/books/married-to-the-evil-wizard-king.js" /* webpackChunkName: "component---src-pages-en-books-married-to-the-evil-wizard-king-js" */),
  "component---src-pages-en-books-skin-deep-js": () => import("./../../../src/pages/en/books/skin-deep.js" /* webpackChunkName: "component---src-pages-en-books-skin-deep-js" */),
  "component---src-pages-en-books-snapshots-from-the-sea-js": () => import("./../../../src/pages/en/books/snapshots-from-the-sea.js" /* webpackChunkName: "component---src-pages-en-books-snapshots-from-the-sea-js" */),
  "component---src-pages-en-books-swordbright-js": () => import("./../../../src/pages/en/books/swordbright.js" /* webpackChunkName: "component---src-pages-en-books-swordbright-js" */),
  "component---src-pages-en-books-the-blood-prince-js": () => import("./../../../src/pages/en/books/the-blood-prince.js" /* webpackChunkName: "component---src-pages-en-books-the-blood-prince-js" */),
  "component---src-pages-en-books-the-printer-whisperer-js": () => import("./../../../src/pages/en/books/the-printer-whisperer.js" /* webpackChunkName: "component---src-pages-en-books-the-printer-whisperer-js" */),
  "component---src-pages-en-illustrations-js": () => import("./../../../src/pages/en/illustrations.js" /* webpackChunkName: "component---src-pages-en-illustrations-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-illustrations-js": () => import("./../../../src/pages/illustrations.js" /* webpackChunkName: "component---src-pages-illustrations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-livres-a-fleur-de-peau-js": () => import("./../../../src/pages/livres/a-fleur-de-peau.js" /* webpackChunkName: "component---src-pages-livres-a-fleur-de-peau-js" */),
  "component---src-pages-livres-kalipso-js": () => import("./../../../src/pages/livres/kalipso.js" /* webpackChunkName: "component---src-pages-livres-kalipso-js" */),
  "component---src-pages-livres-le-prince-de-sang-js": () => import("./../../../src/pages/livres/le-prince-de-sang.js" /* webpackChunkName: "component---src-pages-livres-le-prince-de-sang-js" */)
}

